import store from '@/store'
import Presenter from '@/components/pages/manage/item/detail/Presenter'

export default {
  data: () => ({
    item: {}
  }),

  async created () {
    this.item = await store.dispatch('item/getDetail', this.$route.params.uuid)
  },

  render (h) {
    return h(Presenter, {
      props: {
        item: this.item
      },

      on: {
        remove (presenter) {
          store.dispatch('item/remove', presenter.item.uuid)
          store.commit('message/show', {
            message: `アイテム「${presenter.item.name}」を削除しました。`,
            color: 'info',
            timeout: 4000
          })
          presenter.$router.push({ name: 'ManageItemList' }, () => {})
        }
      }
    })
  }
}
