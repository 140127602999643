<template>
  <v-card
    :class="['outset', 'welfare-card', 'mx-16', 'my-10', 'pb-10']"
  >
    <v-card-title>
      アイテム詳細
    </v-card-title>
    <v-card-actions class="justify-end mr-5">
      <v-btn
        rounded
        color="info"
        class="small-outset"
        @click="$router.push({ name: 'ManageItemEdit', params: { uuid: item.uuid } })"
      >
        編集
      </v-btn>
      <confirm-button
        button-text="削除"
        button-color="error"
        dialog-title="削除します"
        dialog-body="よろしいですか？"
        @ok-action="remove"
      />
      <v-btn
        rounded
        outlined
        class="small-outset"
        @click="$router.push({ name: 'ManageItemList' }, () => {})"
      >
        一覧に戻る
      </v-btn>
    </v-card-actions>
    <table class="vertical-table">
      <tr>
        <th>アイテム名</th>
        <td>{{ item.name }}</td>
      </tr>
      <tr>
        <th>単価 (pt)</th>
        <td>{{ item.price ? item.price.toLocaleString() : '' }}</td>
      </tr>
      <tr>
        <th>カテゴリー</th>
        <td>{{ item.categoryName }}</td>
      </tr>
      <tr>
        <th>説明</th>
        <td>{{ item.description }}</td>
      </tr>
      <tr>
        <th>注意事項</th>
        <!-- TODO cssリファクタリング -->
        <td
          style="white-space: pre-wrap;"
        >
          {{ item.precaution ? item.precaution : '-' }}
        </td>
      </tr>
      <tr>
        <th>有効期間</th>
        <td>{{ item.expirationPeriod ? `交換から ${item.expirationPeriod} 日間` : "なし" }}</td>
      </tr>
      <tr>
        <th>メイン画像</th>
        <td>
          <v-img
            v-if="item.mainImgSrc"
            :src="item.mainImgSrc"
            :contain="true"
            height="300"
          />
        </td>
      </tr>
      <tr>
        <th>サブ画像1</th>
        <td>
          <v-img
            v-if="item.subImg1Src"
            :src="item.subImg1Src"
            :contain="true"
            height="300"
          />
          <div
            v-else
          >
            画像が登録されていません
          </div>
        </td>
      </tr>
      <tr>
        <th>サブ画像2</th>
        <td>
          <v-img
            v-if="item.subImg2Src"
            :src="item.subImg2Src"
            :contain="true"
            height="300"
          />
          <div
            v-else
          >
            画像が登録されていません
          </div>
        </td>
      </tr>
      <tr>
        <th>サブ画像3</th>
        <td>
          <v-img
            v-if="item.subImg3Src"
            :src="item.subImg3Src"
            :contain="true"
            height="300"
          />
          <div
            v-else
          >
            画像が登録されていません
          </div>
        </td>
      </tr>
      <tr>
        <th>サブ画像4</th>
        <td>
          <v-img
            v-if="item.subImg4Src"
            :src="item.subImg4Src"
            :contain="true"
            height="300"
          />
          <div
            v-else
          >
            画像が登録されていません
          </div>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import ConfirmButton from '@/components/common/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    remove () {
      this.$emit('remove', this)
    }
  }
}
</script>
